<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div>Hello Import</div>
</template>

<script>
  export default {
    name: 'Import'
  }
</script>
